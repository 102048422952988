import './bootstrap';
/*import '../css/app.css';*/
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import '../css/styles/layout.scss';

import {createApp, h, reactive} from 'vue';
import {createInertiaApp, InertiaLink} from '@inertiajs/inertia-vue3';
import {InertiaProgress} from '@inertiajs/progress';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';
import PrimeVue from 'primevue/config';
import StyleClass from 'primevue/styleclass';
import Tooltip from 'primevue/tooltip';
import Ripple from 'primevue/ripple';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import DataTable from "primevue/datatable"
import Column from "primevue/column";
import Button from 'primevue/button';
import InputText from "primevue/inputtext";
import Card from 'primevue/card';
import Dialog from 'primevue/dialog';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Panel from 'primevue/panel';
import Fieldset from 'primevue/fieldset';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import Divider from 'primevue/divider';
import AutoComplete from 'primevue/autocomplete';
import MultiSelect from 'primevue/multiselect'
import FileUpload from 'primevue/fileupload';
import Chip from 'primevue/chip';
import InlineMessage from 'primevue/inlinemessage'
import ToggleButton from 'primevue/togglebutton';
import Skeleton from 'primevue/skeleton';
import VueWriter from "vue-writer";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

InertiaProgress.init();

createInertiaApp({
    title  : (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const myApp = createApp({render: () => h(App, props)})

            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(PrimeVue, {ripple: true, inputStyle: 'outlined'})
            .use({
                install(App) {
                    App.config.globalProperties.$dateDisplayFormat = (timestamp, withTime = true) => {
                        if (!timestamp) return null;
                        const options = {
                            year : 'numeric',
                            month: 'short',
                            day  : '2-digit'
                        };
                        if (withTime) {
                            options.hour = '2-digit';
                            options.minute = '2-digit';
                            options.hour12 = false;
                        }

                        return new Intl.DateTimeFormat('en-US', options).format(timestamp);
                    }

                    App.config.globalProperties.$utils = {
                        numberToWord         : (number) => {
                            if (isNaN(Number(number))) {
                                return 0;
                            }
                            switch (Number(number)) {
                                case 1:
                                    return "Once";
                                case 2:
                                    return "Twice";
                                case 3:
                                    return "Thrice";
                                default:
                                    return `${number} times`;
                            }
                        },
                        statusTagSeverity    : (status) => {
                            switch (status?.toLowerCase()) {
                                case 'uploaded':
                                    return 'warning';
                                case 'import_error':
                                case 'deleted':
                                    return 'danger';
                                case 'queued':
                                case 'proceed':
                                    return 'info';
                                case 'processing':
                                    return 'primary';
                                case 'processed':
                                case 'complete':
                                    return 'success';
                                default:
                                    return 'secondary'
                            }
                        },
                        statusTextForDisplay : (status) => {
                            let statusText = status;
                            switch (status) {
                                case 'proceed':
                                    statusText = "Queued";
                                    break;
                                case 'uploaded':
                                case 'import_error':
                                case 'deleted':
                                case 'processing':
                                case 'complete':
                                    statusText = status;
                                    break;
                                case 'processed':
                                    statusText = 'processing';
                                    break;
                                default:
                                    statusText = status;
                            }
                            return statusText.charAt(0).toUpperCase() + statusText.substring(1).toLowerCase();
                        },
                        capitalizeFirstLetter: (string) => {
                            return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
                        }
                    }
                }
            })
            .use(VueWriter)
            .component('inertia-link', InertiaLink)
            .component('router-link', {
                props   : ['to', 'custom'],
                template: `
                    <inertia-link :href="to">
                    <slot/>
                    </inertia-link>`
            })
            .use(ToastService)
            .use(ConfirmationService)
            .directive('tooltip', Tooltip)
            .directive('styleclass', StyleClass)
            .directive('badge', BadgeDirective)
            .directive('ripple', Ripple)
            .component('Badge', Badge)
            .component("DataTable", DataTable)
            .component("Column", Column)
            .component("Button", Button)
            .component("InputText", InputText)
            .component("Card", Card)
            .component('Dialog', Dialog)
            .component('Toast', Toast)
            .component('Panel', Panel)
            .component('Fieldset', Fieldset)
            .component('Password', Password)
            .component('Dropdown', Dropdown)
            .component('ConfirmDialog', ConfirmDialog)
            .component('Divider', Divider)
            .component('AutoComplete', AutoComplete)
            .component('MultiSelect', MultiSelect)
            .component('FileUpload', FileUpload)
            .component('Chip', Chip)
            .component('InlineMessage', InlineMessage)
            .component('ToggleButton', ToggleButton)
            .component('Skeleton', Skeleton);

        /*
            * when checking theme change
            *  - AppMenu.vue (resources/js/Layouts/AppMenu.vue(
            *     - Update toggleTheme
            *     - Update variable changeDisplayTheme to true if default is light else false
            *  - app.blade.php (resources/views/app.blade.php)
            *     - update theme link
            *  - app.js (resources/js/app.js)
            *     - update theme name and darkTheme boolean
             */

        myApp.config.globalProperties.$appState = reactive({theme: 'saga-purple', darkTheme: false});
        return myApp.mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
